import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/IndexPage.vue')
      },
      {
        path: 'product-list/:id',
        component: () => import('pages/ProductListPage.vue'),
      },
      {
        path: 'product-list-sec/:id',
        component: () => import('pages/ProductListSecPage.vue'),
      },
      { path: 'product/:id', component: () => import('pages/ProductPage.vue') },
      {
        path: 'solution/:id',
        component: () => import('pages/SolutionPage.vue'),
      },
      {
        path: 'news',
        component: () => import('pages/NewsPage.vue'),
      },
      {
        path: 'news/search',
        component: () => import('pages/SearchPage.vue'),
      },
      {
        path: 'news/:id',
        component: () => import('pages/DocumentPage.vue'),
      },
      {
        path: 'about',
        redirect: '/about/intro',
      },
      {
        path: 'about/intro',
        component: () => import('pages/IntroPage.vue'),
      },
      {
        path: 'about/honor',
        component: () => import('pages/HonorPage.vue'),
      },
      {
        path: 'about/culture',
        component: () => import('pages/CulturePage.vue'),
      },
      {
        path: 'about/contact',
        component: () => import('pages/ContactPage.vue'),
      },
      {
        path: 'join-us',
        component: () => import('pages/JoinUsPage.vue'),
      },
      {
        path: 'support',
        component: () => import('pages/SupportPage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
