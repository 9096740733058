import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { Notify } from 'quasar';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const api = axios.create({
  baseURL: '/',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'RequestVerificationToken',
  headers: {
    // 'Accept-Language': 'en',
    '__tenant': '3a123f1d-a5af-8528-7609-6e3313845668'
    // '__tenant': '3a123f1d-d153-6df5-7d56-3e5809b0a7dc'
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;

    let errMessage = '';
    if (response?.data) {
      const data = response?.data as any;
      errMessage =
        data.error_description ?? data.error.details ?? data.error.message;
    } else {
      errMessage = `发生${response?.status}错误，${response?.statusText ?? '请求出错，请稍候重试。'
        }`;
    }
    if (errMessage) {
      Notify.create({
        type: 'negative',
        position: 'top',
        message: errMessage,
      });
    }

    return Promise.reject(error);
  }
);

export default boot(({ app, ssrContext }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api
  if (ssrContext) {
    api.interceptors.request.use((config) => {
      config.baseURL = 'https://' + ssrContext.req.hostname;
      return config;
    });
  }

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
