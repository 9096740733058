import { App } from 'vue';
import { createI18n } from 'vue-i18n';
import zh from './modules/zh.json'
import en from './modules/en.json'

const messages = {
  zh,
  en
}

export default {
  install(app: App, options: { lang: string }) {
    const i18n = createI18n({
      legacy: false,
      globalInjection: true,  // 全局生效$t
      locale: options.lang || 'en',
      fallbackLocale: 'en',   // 设置备用语言
      messages: messages,
    });

    app.use(i18n);
  }
}
