import { boot } from 'quasar/wrappers';
import i18n from 'src/i18n';

export default boot(({ app, ssrContext }) => {
  let lang: string | null | undefined = 'zh';
  if (ssrContext) {
    const url = ssrContext.req.hostname.includes('en');
    if (url) {
      lang = 'en';
    }
  } else {
    const url = window.location.host.includes('en');
    if (url) {
      lang = 'en';
    }
  }

  app.use(i18n, { lang });
});
